<template>
  <PageContent title="Invoice">
    <div class="columns">
      <div class="column is-5">
        <div class="box">
          <div class="columns">
            <div class="column is-7"><h4>Original Document</h4></div>
            <div class="column is-5 has-text-right">
              <b-button type="is-outline" icon-left="delete" @click="file = null" v-if="file">
                Replace
              </b-button>
            </div>
          </div>

          <!-- Show the file -->
          <div v-if="file || uploadedFile" class="has-text-centered">
            <doc-viewer
              :file="file"
              :container-id="uploadedFile && uploadedFile.containerId"
              :document-id="uploadedFile && uploadedFile.documentId"
            />
          </div>

          <div v-else>
            <!-- Show the Upload Form -->
            <b-field class="file">
              <b-upload v-model="file" drag-drop accept="application/pdf">
                <section class="section has-width-500">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="file-pdf" size="is-large"> </b-icon>
                    </p>

                    <p v-if="file">
                      {{ file.name }}
                    </p>
                    <p v-else>
                      Drop a PDF file here, or click to select a file from your computer.
                    </p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <!-- <b-button type="is-primary" icon-left="upload" :disabled="!file" @click="submitFile">
                Upload
              </b-button> -->
          </div>
        </div>
      </div>
      <div class="column is-7">
        <div class="box">
          <h4>Invoice</h4>

          <invoice-form />
        </div>
        <!-- 2. Edit PDF -->
      </div>
    </div>
  </PageContent>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import PageContent from '@/containers/PageContent'
import InvoiceForm from '@/forms/InvoiceForm'
import DocViewer from '@/components/DocViewer'

export default {
  components: { DocViewer, PageContent, InvoiceForm },
  computed: {
    ...mapState('hoa', [
      'unit',
      'ownerAccountTransactions',
      'ownerAccountChargeTypeBalances',
      'ownerAccountBalance',
      'vendors'
    ]),
    ...mapGetters('hoa', ['ownersList']),
    ...mapState('invoices', ['uploadedFile'])
  },

  data: () => ({
    file: null,
    loading: true
    // filters: {}
  }),

  mounted() {
    this.refreshOwnersAndVendors()
  },

  watch: {
    // unit: 'reload',
    // filters: 'reload',
    // file(o) {
    //   var reader = new FileReader()
    //   reader.onload = e => this.$emit('load', e.target.result)
    //   const txt = reader.readAsText(o)
    //   debugger
    // }
  },

  methods: {
    ...mapActions('invoices', ['uploadDocument']),
    ...mapActions('hoa', ['reloadHOA']),

    onFilterChange(filters) {
      this.filters = { ...this.filters, ...filters }
    },

    submitFile() {
      this.uploadDocument(this.file)
    },

    refreshOwnersAndVendors: debounce(async function() {
      this.reloadHOA()
    }, 500)
  }
}
</script>
