<template>
  <div class="invoice-form">
    <b-field label="Payee" horizontal>
      <b-autocomplete
        size="is-small"
        field="name"
        icon="account-search"
        placeholder="Owner or Vendor"
        :data="payeeSuggestions"
        v-model="payeeFilter"
        @select="option => (owner = option)"
        open-on-focus
        keep-first
        clearable
      >
        <template slot-scope="props">
          <div class="media" v-if="props.option._type == 'vendor'">
            <div class="media-left"><b-icon icon="domain" size="small" /></div>
            <div class="media-content">
              {{ props.option.name }}
              <br />
              <small>
                Vendor
              </small>
            </div>
          </div>
          <div class="media" v-else-if="props.option._type == 'owner'">
            <div class="media-left"><b-icon icon="home" size="small" /></div>
            <div class="media-content">
              {{ props.option.name }}
              <br />
              <small> Owner: Unit ID {{ props.option.unitID }} </small>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>

    <b-field label="Account" horizontal>
      <b-autocomplete size="is-small" />
    </b-field>

    <b-field label="Invoice #" horizontal>
      <b-input />
    </b-field>

    <b-field horizontal label="Billing Period">
      <b-datepicker
        placeholder="Select start date..."
        icon="calendar-today"
        v-model="startDate"
        :max-date="maxStartDate"
        :min-date="minStartDate"
        trap-focus
        editable
      >
      </b-datepicker>
      <div
        class="has-text-centered h7"
        :style="{
          margin: 0,
          paddingTop: '.4em'
        }"
      >
        to
      </div>
      <b-datepicker
        placeholder="Select end date..."
        icon="calendar-today"
        v-model="endDate"
        :max-date="maxEndDate"
        :min-date="minEndDate"
        trap-focus
        editable
      >
      </b-datepicker>
    </b-field>

    <b-field label="Posting Date" horizontal>
      <b-datepicker> </b-datepicker>
    </b-field>

    <b-field label="Billing Period" horizontal>
      <b-input size="is-small" />
    </b-field>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'

// const defaultStart = moment()
//   .subtract(1, 'month')
//   .startOf('month')

// const defaultEnd = moment()
//   .subtract(1, 'month')
//   .endOf('month')

export default {
  components: {},

  data: () => ({
    owner: null,
    vendor: null,

    startDate: null, // defaultStart.toDate(),
    endDate: null, // defaultEnd.toDate(),

    payeeFilter: null,
    vendorFilter: null,

    loading: false
  }),

  computed: {
    ...mapGetters('hoa', ['payeesList']),
    ...mapState('invoices', ['uploadedFile']),

    payeeSuggestions() {
      if (this.payeeFilter) {
        return this.payeesList.filter(a => a._search.indexOf(this.payeeFilter.toLowerCase()) !== -1)
      } else {
        return this.payeesList
      }
    },

    minStartDate() {
      return moment('01-01-1980').toDate()
    },

    maxStartDate() {
      return moment(this.endDate)
        .subtract(1, 'day')
        .toDate()
    },

    minEndDate() {
      return moment(this.startDate)
        .add(1, 'day')
        .toDate()
    },

    maxEndDate() {
      return moment().toDate()
    }
  }
}
</script>
